<template>
	<div>
		<b-carousel :interval="0" controls indicators v-if="room.RoomImg && room.multimedias.length > 1" >						
			<b-carousel-slide v-for="(img, index) in room.multimedias" :key="index" >
				<template #img>
					<b-card-img	:src="img.multimedia" height="320" />
				</template>
			</b-carousel-slide>
		</b-carousel>
		<b-card-img v-else  :src="imgRoom" height="320" />
	</div>
</template>

<script>
export default {
	props: {
		room:{
			type: Object,
			required: true
		}			
	},
	 data(){
		return {			    			
			imgUrl: `https://backsales.thefives.com.mx/media/imgdefault.jpg`, //link fijo de back

		}
  	},
	computed:{
		imgRoom(){
			// room.RoomImg corresponde al valor de room.multimedias[0].multimedia
			return this.room.RoomImg ? this.room.multimedias[0].multimedia : this.imgUrl
		}
	}
}
</script>

