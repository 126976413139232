<template>
	<b-modal
		size="sm"
		:id="'modal-cancelReserv' + detail.id"
		:title="detail.code + ' | ' + detail.guestname"
		ok-only
		centered
		hide-footer
		no-close-on-backdrop
	>
		<b-card-text>
			<div class="d-flex justify-content-between ">
				<b-badge variant="primary" > <feather-icon icon="MoonIcon" class="mr-25" /> <span> {{$t('Nights num', {nights: detail.nights})}}</span> </b-badge>
				<b>{{detail.datein}} <feather-icon icon="ArrowRightIcon" class="mr-25" />{{detail.dateout}}</b>
			</div>
			<br>

			<div class="text-center pb-0">
				<h4><strong>{{ $t('cancelling book') }}</strong></h4>
			</div>

			<div class="isCentered isSpinner mt-2 mb-1" v-if="isLoading">
				<center>
				<b-spinner class="isCentered" label="Spinning"></b-spinner> <br>
				{{ $t('loading') }}
				</center>
			</div>

			<validation-observer tag="form" v-slot="{ invalid }" >
				<b-form>
					<b-row class="mb-2">
						<b-col md="12">
						<validation-provider :name="$t('Reason for cancellation')" rules="required">
							<b-form-group slot-scope="{ valid, errors }" :label="$t('Reason for cancellation')">
							<b-form-select
								:disabled="detail.status == 6"
								:state="errors[0] ? false : valid ? true : null"
								v-model="cancelRsv.motiveCancel"
								:clearable="false"
							>
								<option :value="null">{{ $t('Select an option') }}</option>
								<option v-for="motivo in cancellationReasons" :key="motivo.id" :value="motivo.id"> {{ $t(motivo.name) }} </option>
							</b-form-select>
							<b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>
						</b-col>
						<b-col md="12">
						<validation-provider :name="$t('cancelation note')" rules="max:200">
							<b-form-group :label="$t('cancelation note')" slot-scope="{ valid, errors }">
							<b-form-textarea
								:disabled="detail.status == 6"
								v-model="cancelRsv.cancelNote"
								:placeholder="$t('Write cancelation note')"
								:maxlength="200"
								rows="2"
								:state="errors[0] ? false : valid ? true : null"
							/>
							<b-form-invalid-feedback> {{ errors[0] }} </b-form-invalid-feedback>
							</b-form-group>
						</validation-provider>
						</b-col>
						<!-- <b-col md="6" /> -->
						<b-col md="12" v-if="requiresTerms" class="half-margin">
							<b-form-checkbox
								v-model="cancelRsv.termsStatus"
								:value="true"
								:unchecked-value="false"
							> {{$t('I accept')}}
								<b-link :to="{name: 'termsConditions', params: { term: 20, lang: user.languageId } }" target="_blank"> {{ $t('terms and use') }} </b-link>
							</b-form-checkbox>
						</b-col>
					</b-row>
					<div class="btn-confirm-cancel">
						<b-button
							type="button"
							@click.prevent="cancelBoooking(0)"
							:disabled="invalid || isLoading || (requiresTerms && !cancelRsv.termsStatus)"
							variant="danger"
						> {{ $t('confirm cancel') }}
						</b-button>
					</div>
				</b-form>
			</validation-observer>
		</b-card-text>
	</b-modal>
</template>

<script>

import * as moment from 'moment'
import { mapState, mapActions } from "vuex"
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import { currentDate } from '@/helpers/market'
import { utils } from '@/modules/owners/mixins/utils'

export default {
	mixins:[utils],
	directives: { Ripple },
	props: {
		detail: {
			type: Object,
			required: true,
		},
		isLoading:{
			type: Boolean,
			required: true,
		},
		cancelRsv: {
			type: Object,
		},
		requiresTerms:{
			type: Boolean,
			required: true
		}
	},
	components: { vSelect, },
	watch:{
		// Si el año obtenido desde benefits cambia, reseteo si se tiene algo en las fechas del calendario
		cancelRsv: {
			deep: true,
			handler() {
				if(this.cancelRsv.penaltyNights==this.detail.nights) this.isValidNigths=true
			},
		}
	},
	data() {
		return {
			isValidNigths:false,
			penaltyNightsMinimun:3,
			currentDate: currentDate()
		};
	},
	computed: {
		...mapState('auth', ['user']),
		...mapState('owners', ['cancellationReasons']),
		requiresPenalty(){
			let requiresPenalty = false
			const currentDate = moment().startOf('day')
			const dateCheckin = moment(this.detail.datein)
			// dias de diferencia entre la fecha actual y la fecha de checkin
			const daysDiff = dateCheckin.diff(currentDate, 'days')
			//verifica si la fecha de checkin supera a la fecha de fecha actual
			// const checkinDateIsValid = moment(this.detail.datein).isAfter(this.currentDate, 'day')
			if( daysDiff < 10 ){ // si los dias de difencia es meno a diez, se requiere aplicar penalización
				requiresPenalty = true
			}
			return requiresPenalty
		},
		cancelMotiveFilled(){
			return (Boolean(this.cancelRsv.motiveCancel) && this.cancelRsv.motiveCancel != 1 ) || (this.cancelRsv.motiveCancel == 1 && this.cancelRsv.cancelNote != '')
		}
	},
	methods: {
		...mapActions('owners', ['fetchBookingsPenalities']),
		async cancelBoooking(confirm){

			// validar si es admin desde mixin en utils
            if( this.isAdmin ){
				this.$root.$emit('bv::hide::modal', 'modal-cancelReserv' + this.detail.id)
                return this.$swal.fire({icon: "error", title: `${this.$t("Restricted Access")}`, html: `${this.$t("Text Restricted Access Admin")}`});
            }

			const { cancelNote } = this.cancelRsv

			const penalities = await this.fetchBookingsPenalities(this.detail.id)

			if (penalities) {
				this.$root.$emit('bv::hide::modal', 'modal-cancelReserv' + this.detail.id)
				return this.$swal.fire({
					icon: "warning",
					title: `${this.$t("cannot cancell reservation")}`,
					text: `${this.$t("contact econcierge to cancell this booking")}`,
				})
			}
			
			const payload = {
				id: this.detail?.id,
				user: 116,
				motiveCancel: 26,
				cancelNote,
				nights: this.detail?.nights,
				penaltynights: 0,
				isCxl: confirm, // indico si es una confirmación de cancelación (0), si procede (1) o no (2)
				isweb: true
			}			
			this.$emit('cancel-rsv-owner', payload)
		}
	},
}
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
.btn-hidden{
  display: none;
  visibility: hidden;
}
</style>
<style scoped>
.half-margin{
  margin: 0.5rem 0 !important;
}
.btn-confirm-cancel{
	display: flex;	
	justify-content: flex-end;	
}
</style>

