export const optionsTrip = [
    { value:  'Arrival', text:  'Arrival' },
    { value:  'Departure', text:  'Departure' },
    { value:  'Inter Hotel', text:  'Inter Hotel' },
    { value:  'Open Service', text:  'Open Service' },
    { value:  'Transporte Colaborador', text:  'Transporte Colaborador' },
]

export const itemViewOptions = [
    { icon:  'GridIcon', value:  'grid-view' },
    { icon:  'ListIcon', value:  'list-view' },
]
export const sortByOptions = [
    { text:  'Orden alfabético', value:  'alfabetico' },
    { text:  'Precio Ascendente', value:  'price-asc' },
    { text:  'Precio Descendente', value:  'price-desc' },
]

export const filters = {
    q:  '',
    priceRangeDefined:  'all',
    priceRange:  [0, 100],
    categories:  [],
    brands:  [],
    ratings:  null,
    page:  1,
    perPage:  12,
}

export const adultOptions = [
    { value:  1, text:  1 },
    { value:  2, text:  2 },
    { value:  3, text:  3 },
    { value:  4, text:  4 },
    { value:  5, text:  5 },
    { value:  6, text:  6 },
    { value:  7, text:  7 },
    { value:  8, text:  8 },
]

export const childrenOptions = [
    { value:  0, text:  0 },
    { value:  1, text:  1 },
    { value:  2, text:  2 },
    { value:  3, text:  3 },
    { value:  4, text:  4 },
    { value:  5, text:  5 },
    { value:  6, text:  6 },
    { value:  7, text:  7 },
    { value:  8, text:  8 },
    { value:  9, text:  9 },
]

export const ageChildrenOptions = [
    { value:  1, text:  1 },
    { value:  2, text:  2 },
    { value:  3, text:  3 },
    { value:  4, text:  4 },
    { value:  5, text:  5 },
    { value:  6, text:  6 },
    { value:  7, text:  7 },
    { value:  8, text:  8 },
    { value:  9, text:  9 },
    { value:  10, text:  10 },
    { value:  11, text:  11 },
    { value:  12, text:  12 },
    { value:  13, text:  13 },
    { value:  14, text:  14 },
    { value:  15, text:  15 },
    { value:  16, text:  16 },
    { value:  17, text:  17 },
]

export const mockSocialPromos = [
    {
        id: 1,
        name: "Descuento del 10%",
        description: "Duis bibendum. Morbi non quam nec dui luctus rutrum. Nulla tellus.",
        socialNetworks: [
            "facebook",
            "instagram",
            "linkedin",
            "link"
        ],
        urlImage: "https://images.pexels.com/photos/338504/pexels-photo-338504.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        code: "TFB-404-ABCD",
    },
    {
        id: 2,
        name: "Descuento del 30%",
        description: "Fusce consequat. Nulla nisl. Nunc nisl.",
        socialNetworks: [
            "facebook",
            "instagram",
            "linkedin",
            "link"
        ],


        urlImage: "https://images.pexels.com/photos/1458457/pexels-photo-1458457.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        code: "TFB-404-EFGH",
    },
    {
        id: 3,
        name: "Descuento por temporada",
        description: "Duis aliquam convallis nunc. Proin at turpis a pede posuere nonummy. Integer non velit..",
        socialNetworks: [
            "facebook",
            "instagram",
            "linkedin",
            "link"
        ],
        urlImage: "https://images.pexels.com/photos/258154/pexels-photo-258154.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        code: "TFB-404-IJKL",
    },
    {
        id: 4,
        name: "Descuento de membresía",
        description: "Quisque porta volutpat erat. Quisque erat eros, viverra eget, congue eget, semper rutrum, nulla. Nunc purus.",
        socialNetworks: [
            "facebook",
            "instagram",
            "linkedin",
            "link"
        ],
        urlImage: "https://images.pexels.com/photos/261102/pexels-photo-261102.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        code: "TFB-404-MNOP",
    },
    {
        id: 5,
        name: "Promoción por lealtad",
        description: "Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. .",
        socialNetworks: [
            "facebook",
            "instagram",
            "linkedin",
            "link"
        ],
        urlImage: "https://images.pexels.com/photos/1802255/pexels-photo-1802255.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        code: "TFB-404-QRST",
    },
    {
        id: 6,
        name: "Promoción por aniversario",
        description: "Phasellus in felis. Donec semper sapien a libero. Nam dui.",
        socialNetworks: [
            "facebook",
            "instagram",
            "linkedin",
            "link"
        ],
        urlImage: "https://images.pexels.com/photos/2096983/pexels-photo-2096983.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        code: "TFB-404-UVWX",
    },
    {
        id: 7,
        name: "Promoción por compra anticipada",
        description: "Maecenas leo odio, condimentum id, luctus nec, molestie sed, justo. ",
        socialNetworks: [
            "facebook",
            "instagram",
            "linkedin",
            "link"
        ],
        urlImage: "https://images.pexels.com/photos/60217/pexels-photo-60217.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        code: "TFB-404-YZ01",
    },
    {
        id: 8,
        name: "Oferta de regalo con compra",
        description: "Cras mi pede, malesuada in, imperdiet et, commodo vulputate, justo. In blandit ultrices enim. Lore.",
        socialNetworks: [
            "facebook",
            "instagram",
            "linkedin",
            "link"
        ],
        urlImage: "https://images.pexels.com/photos/749319/pexels-photo-749319.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2",
        code: "TFB-404-2345",
    }
]

export const accesTypes = [
    {id: 1, name: 'VI'},
    // {id: 2, name: 'CL'},
    // {id: 3, name: 'MT'},
    // {id: 4, name: 'PV'},
    // {id: 5, name: 'MD'}
]

export const bookingBadgeStatus = [
    {status: '1', variant: 'success', statusName: "Confirmed"}, //  1->Pending Confirmation // se cambia a comfirmada
    {status: '2', variant: 'success', statusName: "Confirmed"}, // 2-> es confirmada
    {status: '3', variant: 'danger', statusName: "Cancelled"},
    {status: '4', variant: 'primary', statusName: "To authorize}"},
    {status: '5', variant: 'success', statusName: "Confirmed"}, // 5-> Pending Modification
    {status: '6', variant: 'danger', statusName: "Cancelled"} // Pending cancel
]