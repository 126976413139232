<template>    
    <b-modal
		size="md"
		id="user-restric-modal"
		title="Aceptar"
		ok-only
		centered
		no-close-on-backdrop
		hide-header
        modal-bg-variant="custom-opacity"
		header-bg-variant="danger"
	>
		<div class="modal-container-restrict">
			<div v-if="langLocale === 'en' ">
				<p class="title-p">Restricted Access</p>
				<br>
				<div class="text-p">
					<p>You have pending the signature of one or more executive reports.</p>
					<p>For more information, please contact E-Concierge by calling: <strong>+52 (984) 242 0151</strong>.</p>
				</div>
			</div>
			<div v-else>
				<p class="title-p">Acceso restringido</p>
				<br>
				<div class="text-p">
				<p>Tiene pendiente la firma de uno o más informes ejecutivos.</p>
				<p>Para más información, póngase en contacto con E-Concierge llamando al: <strong>+52 (984) 242 0151</strong>.</p>
			</div>
			</div>
		</div>
	</b-modal>
</template>

<script>

import { mapActions, mapState, mapGetters  } from 'vuex'

import { utils } from '@/modules/owners/mixins/utils'

export default {
	mixins: [ utils ],	
	computed: {
		...mapState("auth", ["user"]),	
		...mapGetters('auth',['contractSelected']),
        langLocale(){
            return this.$i18n.locale
        }
	},
 
}
</script>
<style>

.modal-container-restrict {
	margin-top: 1rem;
	color: #0e0d0d;
    /* border: 6px solid #6b6a6a; */
    /* border-radius: 0!important; */
    /* box-shadow: 0 5px 20px 0 rgba(34, 41, 47, 0.1); */
}

.modal-footer{
	display: block;
	text-align: center !important;
}

.title-p{
	font-size:1.875em; 
	font-weight: 600; 
	text-align: center; 
	word-wrap: break-word;
}

.text-p{
	font-size: 1.05em; 
	text-align: center; 
	word-wrap: break-word;
}


/*
{ opacity: .3; background-color: rgb(0,0,0,.3); height: 100%; } modal <--cuadro gris (border) element { position: fixed; top: 50%; left: 50%; transform: translate(-50%, -50%); display: block; background: #999; width: 600px; height: 300px; padding: 5px; } inner modal <--cuadro blanco element { background: #fff; display: block; width: 100%; height: 100%; padding: 10px; text-align: justify; } clase para los nombres de marcas .hlgt { font-style: italic; font-weight: bold; } */

</style>

